import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, SideBox } from "../components/styledComponents"
import ArrowMore from "../components/arrowMore"
import LastCTASection from "../components/lastCTASection"
import { InlineWidget } from "react-calendly"
import ContactForm from "../components/contactForm"
import {
  BigMobileFont,
  MediumMobileFont,
  SmallMobileFont,
} from "../components/typography"
import BaseImg from "../components/baseImg"
import CenterBorderedHeader from "../components/centerBorderedHeader"
import SidesBorderedBox from "../components/sidesBorderedBox"
import CaseStudySection from "../components/caseStudySection"
import ArticlesGrid from "../components/articlesGrid"
import ListSection from "../components/listSection"

const TwoColumns = styled.div`
  display: flex;
  align-items: center;
  margin: ${(props) => props.desktopMargin};
  @media (max-width: ${(props) => props.breakpoint}) {
    margin: 0;
    flex-direction: column;
  }

  .gatsby-image-wrapper {
    height: auto !important;
  }
`
const ContactInfo = styled.div`
  padding: 32px;

  p {
    margin-bottom: 0.5rem;
  }
`

export const BaseButton = styled.button`
  display: inline-block;
  position: relative;
  text-align: center;
  line-height: 1.25;
  font-family: "Montserrat";
  font-size: ${(props) =>
    ["small", "medium"].includes(props.size) ? "1rem" : "1.125rem"};
  padding: ${(props) =>
    props.size === "small"
      ? "0.625rem 3rem"
      : props.size === "medium"
      ? "0.75rem 2.5rem"
      : "0.9375rem 3rem"};
  /* small medium big */
  @media (max-width: 1195px) {
    font-size: 1rem;
    padding: 0.625rem 2rem;
  }
  border: none;
  color: #fff;
  font-weight: bold;
  background: ${(props) => props.theme.secondary};
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.secondary};
  transition: color 0.1s ease-in-out, background 0.2s ease-in-out;

  :focus {
    border-style: dashed;
    background: #fff;
    color: ${(props) => props.theme.secondary};
    outline: none;
  }

  :hover {
    color: ${(props) => props.theme.secondary};
    background: #fff;
  }
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const ColumnImage = styled(BaseImg)`
  width: 47%;
  max-width: 550px;
  margin-bottom: 0;
  @media (max-width: 768px) {
    width: 80%;
  }
`

const EdgeImg = styled(BaseImg)`
  position: relative;
  z-index: -1;
  width: 52%;
  margin: -6% -25vw -5% 0.5%;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`
const ArrowMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0 50px;
  @media (max-width: 768px) {
    margin: 0;
  }
`
const StyledSideBox = styled(SideBox)`
  margin-left: 0;
  padding-left: 0;
  width: 100%;
  margin-bottom: 36px;
  @media (max-width: 768px) {
    padding: 32px 16px;
  }
`
const SideBoxWrapper = styled.div`
  width: 48%;
  min-width: 450px;
  @media (max-width: 768px) {
    width: 100%;
    min-width: auto;
  }
`
const HeroText = styled.p`
  font-size: 1.125rem;
  line-height: 1.6;
  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.5;
  }
`
const LinkedinURL = styled.a`
  font-size: 0.9rem;
  line-height: 1.5;
  color: ${(props) => props.theme.secondary};
`
const NewLanding = ({ pageContext }) => {
  const [activePack, setActivePack] = useState(null)
  const calendarRef = useRef(null)
  const { metaboxes, yoast_meta, blogPosts } = pageContext
  const {
    buttonText,
    clientsSectionTitle,
    gainTitle,
    gainContent,
    title,
    text,
    personImage,
    consultationTitle,
    consultationContent,
    heroImage,
    gainImage,
    lastCTASectionButtonText,
    lastCTASectionButtonUrl,
    lastCTASectionText,
    lastCTASectionTitle,
    consultationLinkText,
    consultationLink,
    calendarTitle,
    calendarUrl,
    displayForm,
    contactHeader,
    contactMail,
    contactMailPrefix,
    contactMobile,
    contactMobilePrefix,
    contactName,
    contactRole,
    contactFormTitle,
    contactImage,
    contactSkype,
    caseStudyImage,
    caseStudyTitle,
    caseStudyText,
    partners,
    caseStudyButtonText,
    caseStudyButtonUrl,
    blogSectionTitle,
    listSectionTitle,
    list,
  } = metaboxes

  const executeScroll = () =>
    calendarRef.current.scrollIntoView({ behavior: "smooth", block: "end" })

  const handleHeroButtonClick = () => {
    if (typeof window !== "undefined") {
      if (window.location.pathname === "/request-consultation/") {
        window.lintrk("track", { conversion_id: 6360514 })
      }
    }
  }

  useEffect(() => {
    const button = document.getElementById("hero-button")
    button.addEventListener("click", handleHeroButtonClick)
    return () => {
      button.removeEventListener("click", handleHeroButtonClick)
    }
  }, [])

  const doesThePageHaveCaseStudy =
    !!caseStudyImage ||
    !!caseStudyTitle ||
    !!caseStudyText ||
    !!partners ||
    !!caseStudyButtonText ||
    !!caseStudyButtonUrl

  console.log("new lanidng")

  return (
    <Layout>
      <SEO
        meta={yoast_meta}
        title={pageContext.title}
        path={pageContext.path}
      />
      <Container style={{ marginTop: "20vh" }}>
        <FlexContainer>
          <SideBoxWrapper>
            <StyledSideBox>
              <h1 style={{ marginBottom: "0.75rem" }}>
                <BigMobileFont desktopFontSize="2.5rem">{title}</BigMobileFont>
              </h1>
              <HeroText dangerouslySetInnerHTML={{ __html: text }} />
              {buttonText && (
                <BaseButton
                  id="hero-button"
                  type="button"
                  onClick={() => {
                    executeScroll()
                  }}
                >
                  {buttonText}
                </BaseButton>
              )}
            </StyledSideBox>
          </SideBoxWrapper>
          <EdgeImg localFile={heroImage.localFile} alt={heroImage.alt_text} />
        </FlexContainer>
        <ArrowMoreWrapper>
          <ArrowMore />
        </ArrowMoreWrapper>
      </Container>

      <Container>
        <FlexContainer style={{ margin: "80px 0" }}>
          <ColumnImage localFile={gainImage.localFile} alt="" />
          <SidesBorderedBox side="right">
            <h2 style={{ marginBottom: "0.75rem" }}>
              <MediumMobileFont desktopFontSize="1.75rem">
                {gainTitle}
              </MediumMobileFont>
            </h2>
            <div
              style={{ fontFamily: "Hind", lineHeight: "1.625" }}
              dangerouslySetInnerHTML={{ __html: gainContent }}
            />
          </SidesBorderedBox>
        </FlexContainer>
      </Container>

      <Container>
        <FlexContainer style={{ margin: "80px 0" }}>
          <SidesBorderedBox side="left">
            <h2 style={{ marginBottom: "0.75rem" }}>
              <MediumMobileFont desktopFontSize="1.75rem">
                {consultationTitle}
              </MediumMobileFont>
            </h2>
            <div
              style={{ fontFamily: "Hind", lineHeight: "1.625" }}
              dangerouslySetInnerHTML={{ __html: consultationContent }}
            />
            <LinkedinURL
              href={consultationLink}
              target="_blank"
              title="CEO BlueOwl"
            >
              {consultationLinkText}
            </LinkedinURL>
          </SidesBorderedBox>
          <ColumnImage localFile={personImage.localFile} alt="" />
        </FlexContainer>
      </Container>

      {doesThePageHaveCaseStudy ? (
        <CaseStudySection
          image={caseStudyImage}
          title={caseStudyTitle}
          text={caseStudyText}
          partners={partners}
          buttonText={caseStudyButtonText}
          buttonUrl={caseStudyButtonUrl}
        />
      ) : null}

      <CenterBorderedHeader>
        <BigMobileFont desktopFontSize="2.5rem">{calendarTitle}</BigMobileFont>
      </CenterBorderedHeader>
      {displayForm === "none" ? (
        <>
          <InlineWidget
            styles={{
              height: "665px",
            }}
            url={calendarUrl}
          />
          <div ref={calendarRef} />
        </>
      ) : (
        <Container>
          <TwoColumns breakpoint="900px" style={{ margin: "96px 0 130px" }}>
            <TwoColumns breakpoint="1195px" desktopMargin="0 32px 0 0">
              <BaseImg
                style={{ width: "280px", height: "280px", marginBottom: "0" }}
                localFile={contactImage.localFile}
                alt=""
              />
              <ContactInfo>
                <h2 style={{ marginBottom: "0.75rem" }}>
                  <SmallMobileFont style={{ fontWeight: "700" }}>
                    {contactHeader}
                  </SmallMobileFont>
                </h2>
                <p style={{ fontWeight: "700" }}>{contactName}</p>
                <p>{contactRole}</p>
                <p>
                  {`${contactMailPrefix} `}
                  <a
                    style={{ fontWeight: "700" }}
                    href={`mailto:${contactMail}`}
                  >
                    {contactMail}
                  </a>
                </p>
                <p>
                  {`${contactMobilePrefix} `}
                  <a
                    style={{ fontWeight: "700" }}
                    href={`tel:${contactMobile}`}
                  >
                    {contactMobile}
                  </a>
                </p>
                <p>
                  skype:{" "}
                  <a
                    style={{ fontWeight: "700" }}
                    href={`skype:${contactSkype}?call`}
                  >
                    {contactSkype}
                  </a>
                </p>
              </ContactInfo>
            </TwoColumns>
            <ContactForm
              activePack={activePack}
              setActivePack={setActivePack}
              title={contactFormTitle}
              isUx
            />
          </TwoColumns>

          <div ref={calendarRef} />
        </Container>
      )}
      {listSectionTitle && list ? (
        <ListSection title={listSectionTitle} items={list} position="center" />
      ) : null}
      <div style={{ margin: "0 0 130px 0" }}>
        <Container>
          <CenterBorderedHeader>
            <BigMobileFont desktopFontSize="2.5rem">
              {blogSectionTitle}
            </BigMobileFont>
          </CenterBorderedHeader>
          <ArticlesGrid posts={blogPosts.data.allWordpressPost.nodes} />
        </Container>
      </div>

      <LastCTASection
        title={lastCTASectionTitle}
        text={lastCTASectionText}
        buttonText={lastCTASectionButtonText}
        buttonUrl={lastCTASectionButtonUrl}
      />
    </Layout>
  )
}

export default NewLanding
