import React from "react"
import styled from "styled-components"

const ArrowButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border: none;
  width: 56px;
  height: 56px;
  color: #fff; /* Fallback for older browsers */
  color: rgba(255, 255, 255, 0);
  transition: color 0.1s ease-in-out, background 0.2s ease-in-out;
  :focus,
  :hover {
    outline: none;
    background: ${(props) => props.theme.secondary};
    div {
      animation: 2s ease-in-out forwards infinite jump;
      ::before {
        border-color: #fff;
      }
    }
  }
  @keyframes jump {
    0% {
      transform: translateY(5px);
    }
    70% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(5px);
    }
  }
`

const ArrowIcon = styled.div`
  position: relative;
  ::before {
    content: "";
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    border-top: 4px ${(props) => props.theme.secondary} solid;
    border-right: 4px ${(props) => props.theme.secondary} solid;
    transform: translate(-50%, -75%) rotate(135deg);
    position: absolute;
  }
`

const ArrowMore = () => {
  return (
    <ArrowButton
      type="button"
      aria-label="Scroll to next section"
      onClick={() =>
        window.scrollTo({
          top: window.scrollY + window.innerHeight - 120,
          behavior: "smooth",
        })
      }
    >
      <ArrowIcon>></ArrowIcon>
    </ArrowButton>
  )
}

export default ArrowMore
